@import "functions";
@import "variables";

.content-wrapper {
  max-width: $media-xxs;
  margin-left: pxToRem(8);
  margin-right: pxToRem(8);

  @media only screen and (min-width: $media-xs) {
    max-width: $media-xs;
  }

  @media only screen and (min-width: $media-s) {
    max-width: $media-s;
  }

  @media only screen and (min-width: $media-m) {
    max-width: $media-m;
    margin-left: pxToRem(16);
    margin-right: pxToRem(16);
  }

  @media only screen and (min-width: $media-l) {
    max-width: $media-l;
    margin: 0 auto;
  }

  @media only screen and (min-width: $media-xl) {
  }

  @media only screen and (min-width: $media-xxl) {
  }
}

.grid-wrapper {
  display: grid;

  @media only screen and (min-width: 768px) {
    grid-template-columns: pxToRem(calc(100% - 340px)) pxToRem(340);

    &--col-gap-8 {
      gap: pxToRem(8);
    }
  }
}

.summary-grid-wrapper {
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;

    &__column {
      display: grid;

      &--first {
        grid-template-columns: pxToRem(calc(100% - 340px)) pxToRem(340);
      }
    }
  }

  &--col-gap-8 {
    gap: pxToRem(8);
  }
}

.grid-col {
  max-width: pxToRem(320);

  @media only screen and (min-width: 375px) {
    max-width: pxToRem(355);
  }

  @media only screen and (min-width: 768px) {
    max-width: pxToRem(752);
  }

  @media only screen and (min-width: 1024px) {
    //max-width: pxToRem(1024 - 40);
  }

  @media only screen and (min-width: 1200px) {
    //max-width: pxToRem(1200 - 40);
  }
}

.form-grid-wrapper {
  display: grid;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: pxToRem(260) auto;
  }
}

.summary-form-grid-wrapper {
  display: grid;
  grid-template-columns: 380px auto 75px;
}

.grid-align-center {
  align-self: center;
}

.grid-align-start {
  align-self: start !important;
}

.grid-align-end {
  align-self: end;
}

.g-col-start-1 {
  grid-column-start: 1;
}

.g-col-start-2 {
  grid-column-start: 2;
}

.g-col-end-2 {
  grid-column-end: 2;
}

.g-col-end-3 {
  grid-column-end: 3;
}

.g-col-end-4 {
  grid-column-end: 4;
}

.self-start {
  align-self: start !important;
}

.self-end {
  align-self: end !important;
}

.ml-4 {
  margin-left: pxToRem(4);
}

.ml-8 {
  margin-left: pxToRem(8);
}

.mr-4 {
  margin-right: pxToRem(4);
}

.mr-6 {
  margin-right: pxToRem(6);
}

.mr-8 {
  margin-right: pxToRem(8);
}

.mb-4 {
  margin-bottom: pxToRem(4);
}

.mb-6 {
  margin-bottom: pxToRem(6);
}

.mb-8 {
  margin-bottom: pxToRem(8);
}

.mb-16 {
  margin-bottom: pxToRem(16);
}

.mb-20 {
  margin-bottom: pxToRem(20);
}

.mb-30 {
  margin-bottom: pxToRem(30);
}

.mb-36 {
  margin-bottom: pxToRem(36);
}

.mb-170 {
  margin-bottom: pxToRem(170);
}

.mt-8 {
  margin-top: pxToRem(8) !important;
}

/* fix */
.mobile-only {
  display: block !important;

  &--flex {
    display: flex !important;
  }

  @media only screen and (min-width: 768px) {
    display: none !important;
  }
}

.desktop-only {
  display: none !important;

  @media only screen and (min-width: 1024px) {
    display: block !important;

    &--flex {
      display: flex !important;
    }
  }
}

.tablet-visible {
  @media only screen and (min-width: 768px) {
    display: block !important;
  }
}