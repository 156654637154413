@import "functions";

/* 320 */
$media-xxs: 320px;
/* 375 */
$media-xs: 375px;
/* 768 */
$media-s: 768px;
/* 1024 */
$media-m: 1024px;
/* 1200 */
$media-l: 1200px;
/* 1440 */
$media-xl: 1440px;
/* 1920 */
$media-xxl: 1920px;

/* Variables related to viewport width */
/* 320 */
$wrapper-padding-left: pxToRem(20);
$max-viewport-width: pxToRem(320);
$max-wrapper-width: pxToRem(calc($max-viewport-width - $wrapper-padding-left));

/*
!* 375 *!
@media only screen and (min-width: $media-xs) {
    $max-viewport-width: pxToRem(375);
}
!* 768 *!
@media only screen and (min-width: $media-s) {
    $max-viewport-width: pxToRem(768);
}
!* 1024 *!
@media only screen and (min-width: $media-m) {
    $max-viewport-width: pxToRem(1024);
}
!* 1200 *!
@media only screen and (min-width: $media-l) {
    $max-viewport-width: pxToRem(1200);
}
!* 1440 *!
@media only screen and (min-width: $media-xl) {
    $max-viewport-width: pxToRem(1440);
}
!* 1920 *!
@media only screen and (min-width: $media-xxl) {
    $max-viewport-width: pxToRem(1920);
}*/
