/* You can add global styles to this file, and also import other style files */
@import "styles/main";

// primary font
$primary_font: 'Poppins';

// font-weight
$thin:100;
$light:300;
$regular:400;
$medium:500;
$semi-bold:600;
$bold:700;

html, body {
  font-family: $primary_font;
  font-weight: $regular;
  margin: 0;
}

.mat-tooltip {
  background: #00a187 !important;
  color: #fff;
  padding: 8px;
  font-size: 12px;
  line-height: 18px;
}

.button-with-tooltip {
  position: relative;

  .form-group__inputs {
    flex-grow: 1;
  }


  .button {
    &.hasTooltip {
      padding-right: 40px;
    }
  }

  .extra_tooltip {
    position: absolute;
    top: 9px;
    right: 0;
  }
}