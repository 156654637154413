@import './colors';

app-policy-creator {
  color: $fill-brandSecondary;

  .p-calendar {
    .p-inputtext {
      background: $fill-form-background
        url('^assets/policy-creator/ikony-05-sm.svg') calc(100% - 20px) center
        no-repeat;
    }
  }

  .p-inputtext,
  .p-dropdown {
    background: $fill-form-background;
    color: $fill-brandSecondary;
    border: none;
  }
}

.form-group {
  &__label,
  &__input {
    font-weight: 500;
  }

  &__input {
    &--200 {
      width: 200px;
    }

    &--312 {
      width: 312px;
    }

    &--428 {
      width: 428px;
    }

    &--600 {
      width: 600px;
    }
  }
}

.phone-inputs {
  display: flex;
}
.form-group__input {
  position: relative;

  &__tooltip {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
  }

  &__prefix {
    width: 74px;
    margin-right: 6px;
  }

  &__email-message {
    font-size: 12px;
  }
}

app-dropdown-input {
  &:not(.isSelected) {
    &:last-of-type {
      .dropdown-input__separator {
        display: none;
      }
    }

    &:first-of-type:not(.topCornersSquared) {
      .dropdown-input__label {
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
      }
    }

    &:last-of-type {
      .dropdown-input__label {
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
      }
    }
  }
}
