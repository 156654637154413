$fill-greyDark: #1d1d1d;
$fill-greyLight: #f2f2f2;
$fill-primaryShadow: #faa20078;
$fill-borderGrey: #d5d5d5;
$fill-mediumGrey: #8b8b8b;

/* used in v2 design */
$fill-brandPrimary: #faa200;
$fill-brandSecondary: #004e69;
$fill-form-background: #f2f6f8;
$fill-lines-separator: #e5edf0;
$fill-default-blue: #80a7b4;

/* warranties color scheme */
$brand-primary-warranties: #00a187;

$input-error-color: #f44336;
