@import "colors";
@import "variables";

p-progressspinner.button-spinner {
  width: 35px !important;
  height: 35px !important;
}

.button-spinner {
  width: 35px !important;
  height: 35px !important;
}

p-dropdown,
p-inputnumber,
.p-dropdown,
.p-inputnumber {
  width: 100%;

  &.ng-dirty.ng-invalid {
    input {
      border-color: $input-error-color;
    }
  }

  .--green {
    background: $brand-primary-warranties;
    color: #fff;
    padding-left: 8px;

    .p-dropdown-label {
      color: $fill-form-background;

      &.p-placeholder {
        color: $fill-form-background !important;
        font-weight: 800;
      }
    }

    .p-dropdown-clear-icon {
      color: $fill-form-background;
    }
  }
}

.p-inputtext {
  font-family: 'Poppins', sans-serif;
  height: 40px;
  width: 100%;
  font-weight: bold;
  border-radius: 20px;
  font-size: 14px;
  text-indent: 8px;

  &::placeholder {
    color: #d5d5d5;
    font-weight: 500;
  }
}

.p-inputmask {
  text-transform: uppercase;
}

.p-inputtext.contactInput {
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: none;
  border-bottom: 3px solid white;
  font-size: 15px;
  color: $fill-brandPrimary;
  font-weight: 500;
  background: none;
  margin-right: 5px;
  padding: 12px 0 4px 0;

  &::placeholder {
    color: white;
    font-weight: 500;
  }
}

.p-inputtext.cooperationInput {
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: none;
  border-bottom: 3px solid $fill-brandSecondary;
  font-size: 15px;
  color: $fill-brandPrimary;
  font-weight: 500;
  background: none;
  margin-right: 5px;
  padding: 12px 0 4px 0;

  &::placeholder {
    color: $fill-brandSecondary;
    font-weight: 500;
  }
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.1rem $fill-primaryShadow;
  border-color: $fill-brandPrimary;

  &.isGreen {
    box-shadow: 0 0 0 0.1rem $brand-primary-warranties;
    border-color: $brand-primary-warranties;
  }
}

.p-inputtext:enabled:hover {
  border-color: $fill-brandPrimary;

  &.isGreen {
    border-color: $brand-primary-warranties;
  }
}

.p-field {
  //z-index: 100;

  label {
    margin-left: 12px;
  }
}

.p-error .p-invalid {
  span {
    line-height: 12px;
  }
}

.p-dropdown,
.p-autocomplete,
.p-inputnumber {
  font-weight: bold;
  border-radius: 20px;

  &--dropdownWide {
    .p-dropdown-items-wrapper {
      max-height: 440px !important;
    }
  }
}

.p-checkbox .p-checkbox-box {
  border: 2px solid white;
  background: none;
  width: 20px;
  height: 20px;
  color: $fill-brandSecondary;
}

p-checkbox.is-warranty {
  border: 2px solid #00a187;
  border-radius: 6px;

  .p-checkbox-box:not(.p-disabled):hover {
    border-color: #00a187;
  }

  .p-checkbox-box.p-highlight {
    border-color: #00a187;
    background: #00a187;
  }

  .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: #00a187;
    background: #00a187;
    color: #fff;
  }
}

.cdk-overlay-container {
  font-weight: 500;
}

.p-calendar {
  width: 100%;

  input.isInvalid {
    border-color: $input-error-color;
  }
}

.p-dropdown:not(p-diabled) {
  &.p-focus {
    box-shadow: 0 0 0 0.1rem $fill-primaryShadow !important;
    border-color: $fill-brandPrimary !important;
  }

  /* custom prime component styles for warranties */
  &.isWarranties.p-focus {
    box-shadow: 0 0 0 0.1rem $brand-primary-warranties !important;
    border-color: $brand-primary-warranties !important;
  }
}

.p-dropdown:not(p-diabled) {
  &:hover {
    border-color: $fill-brandPrimary !important;
  }

  /* custom prime component styles for warranties */
  &.isWarranties:hover {
    border-color: $brand-primary-warranties !important;
  }
}

.p-dropdown-label {
  padding-top: 9px;

  &.p-placeholder {
    color: #d5d5d5 !important;
    font-weight: 500;
  }
}

.p-dropdown {
  .p-dropdown-trigger {
    color: $fill-brandPrimary;
  }

  /* custom prime component styles for warranties */
  &.isWarranties .p-dropdown-trigger {
    color: $brand-primary-warranties;
  }

  &.isWarranties .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.1rem $brand-primary-warranties !important;
    border-color: $brand-primary-warranties !important;
  }
}

/* custom prime component styles for warranties */
.isWarranties {
  .p-dropdown-items-wrapper {
    max-height: 264px !important;

    .p-dropdown-item.p-highlight {
      background: $brand-primary-warranties !important;
      border-radius: 16px;
    }
  }

  .p-inputtext:enabled:focus,
  &.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.1rem $brand-primary-warranties !important;
    border-color: $brand-primary-warranties !important;
  }

  .p-inputtext:enabled:hover,
  &.p-inputtext:enabled:hover {
    border-color: $brand-primary-warranties !important;
  }
}

.isWarranties {
  //.dropdown-input__label {
  //  background: $fill-form-background !important;
  //  color: $brand-primary-warranties !important;
  //}

  .dropdown-input__label--isActive {
    background: $brand-primary-warranties !important;
    color: $fill-form-background !important;
  }
}

.p-dropdown-panel {
  border-radius: 26px;
  z-index: 50000;
}

.p-dropdown-items-wrapper {
  max-height: 264px !important;

  .p-dropdown-item.p-highlight {
    background: $fill-brandPrimary !important;
    border-radius: 16px;
  }
}

.p-dropdown-items {
  .p-dropdown-item {
    span {
      text-wrap: balance;
    }
  }
}

.p-component {
  font-family: 'Poppins', sans-serif;
}

.p-overlaypanel {
  width: 280px;
  font-size: 12px !important;
  top: 25px !important;
  left: -20px !important;

  &:before,
  &:after {
    left: 38px !important;
  }

  .p-overlaypanel-content {
    padding: 24px;
    text-align: left;

    p {
      font-size: 12px !important;
    }

    a {
      font-size: 12px !important;
      padding-top: 12px;
      margin-left: 45%;
      color: $fill-brandPrimary;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.rightSidePanel {
  .p-overlaypanel {
    left: -223px !important;

    &:before,
    &:after {
      left: 240px !important;
    }
  }
}

.centralPanel {
  .p-overlaypanel {
    left: -120px !important;

    &:before,
    &:after {
      left: 138px !important;
    }
  }
}

p-calendar {
  width: 100%;
}

.p-calendar {
  .p-datepicker-title {
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      display: inline-block;
      text-align: center;
    }
  }

  .p-datepicker {
    border-radius: 26px;
    min-width: 100% !important;
    max-width: 420px;
    top: 55px !important;
    //height: 300px;

    &-today {
      .p-element {
        background: $fill-default-blue !important;
        color: #fff;
      }
    }

    table td,
    th {
      padding: 0.25rem !important;

      span {
        width: unset !important;
        height: unset !important;
      }
    }

    .p-datepicker-group {
      .p-ripple {
        border-radius: 12px;
        background: $fill-form-background;

        &:focus {
          box-shadow: 0 0 0 0.1rem $fill-primaryShadow;
          border-color: $fill-brandPrimary;
        }

        option.p-selected {
          background: $fill-brandPrimary;
        }
      }
    }

    .p-highlight,
    .p-active,
    .p-focus,
    .p-selected {
      background: $fill-brandPrimary !important;
    }
  }

  &.isWarranties {
    .p-datepicker table td.p-datepicker-today > span.p-highlight {
      border-color: #00a187;
      background: #00a187;
      color: #fff;
    }

    .p-highlight,
    .p-active,
    .p-focus,
    .p-selected {
      border-color: #00a187 !important;
      background: #00a187 !important;
      color: #fff;
    }
  }
}

// confirmation modal

.p-dialog-mask.p-component-overlay {
  backdrop-filter: blur(6px);
}

.p-dialog {
  border-radius: 26px;
  width: 90%;
  background: white;

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    padding: 20px;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    justify-content: center;
    color: $fill-brandSecondary;
    font-weight: 400;
    line-height: 25px;
    text-align: center;

    h1 {
      text-transform: uppercase;
      color: $fill-brandSecondary;
      font-size: 26px;
      letter-spacing: 0.4px;
      line-height: 32px;
      font-weight: bold;
    }

    .container {
      //padding-left: 66px; what was this for ?
      //padding-right: 66px; what was this for ?
      text-align: left;

      p,
      span {
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }

  .p-dialog-footer {
    border-bottom-right-radius: 26px;
    border-bottom-left-radius: 26px;
    text-align: center;
    padding: 20px 16px !important;

    button {
      //margin-right: 26px; what was this for ?
      border-radius: 26px;
      background: $fill-brandSecondary;
      border-color: $fill-brandSecondary;
      height: 42px;
      width: 223px;
      margin: 4px;
      //margin-top: 26px; what was this for ?

      &:focus {
        box-shadow: none;
      }

      span {
        font-size: 14px;
        color: white;
        font-weight: 600;
        @media only screen and (min-width: 768px) {
          font-size: 17px;
        }
      }

      .p-button-icon {
        display: none;
      }
    }

    .container {
      display: flex;
      justify-content: center;

      button {
        width: 129px;
        //mwidth: 280px; what was this for ?
        @media only screen and (min-width: 768px) {
          width: 223px;
          span {
            font-size: 17px;
          }
        }
      }

      .description {
        font-size: 14px;
        font-weight: 300;
        color: $fill-mediumGrey;
        width: 129px;
        @media only screen and (min-width: 768px) {
          width: 223px;
        }
      }
    }
  }
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
  background: $fill-brandPrimary;
  border-color: $fill-brandPrimary;

  .pi {
    transform: translateX(-2px);
  }
}

.p-toast {
  z-index: 1000 !important;
}

// carousel indicators

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $fill-brandSecondary;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: $fill-brandSecondary;
  color: #212529;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  color: $fill-brandSecondary;
  background: white;
  border: none;
}

.p-accordion
.p-accordion-header:not(.p-disabled).p-highlight
.p-accordion-header-link {
  background: white;
  color: $fill-brandPrimary;
}

.p-accordion
.p-accordion-header:not(.p-disabled).p-highlight:hover
.p-accordion-header-link {
  background: white;
  color: $fill-brandPrimary;

  .p-accordion-header-text {
    text-decoration: underline;
  }
}

.p-accordion
.p-accordion-header:not(.p-highlight):not(.p-disabled):hover
.p-accordion-header-link {
  background: white;
  color: $fill-brandPrimary;

  .p-accordion-header-text {
    text-decoration: underline;
  }
}

.p-accordion
.p-accordion-header:not(.p-disabled)
.p-accordion-header-link:focus {
  box-shadow: none;
}

.p-accordion .p-accordion-content {
  border: none;
  color: $fill-greyDark;
}

// progress spinner

.custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
  custom-progress-spinner-color 6s ease-in-out infinite;
}

.custom-spinner {
  &--w40 {
    .p-progress-spinner {
      width: 40px;
      height: 40px;
    }
  }
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
    stroke: $fill-brandPrimary;
  }
  40% {
    stroke: $fill-brandSecondary;
  }
  66% {
    stroke: $fill-brandPrimary;
  }
  80%,
  90% {
    stroke: $fill-brandSecondary;
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@media only screen and (min-width: 768px) {
  .p-inputtext {
    font-family: 'Poppins', sans-serif;
    height: 42px;
    font-weight: bold;
    border-radius: 26px;
    font-size: 17px;
  }

  .p-dropdown,
  .p-inputnumber {
    font-weight: bold;
    border-radius: 26px;

    &--dropdownWide {
      @media only screen and (min-width: 1680px) {
        min-width: 100%;

        .p-dropdown {
          min-width: 100%;
        }
      }
    }
  }

  .p-dropdown-label {
    padding-top: 7px;
  }

  // confirmation modal
  .p-dialog-mask.p-component-overlay {
    backdrop-filter: blur(6px);
  }

  .p-dialog {
    .p-dialog-content {
      //font-size: 17px;
      line-height: 26px;
      max-height: 72vh;

      h1 {
        font-size: 46px;
        letter-spacing: 0.4px;
        line-height: 69px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  // confirmation modal

  .p-dialog {
    max-width: 950px;
  }
  .p-inputtext.contactInput {
    height: 30px;
    font-size: 12px;
    border-width: 2px;
  }
}

@media only screen and (min-width: 1600px) {
  .p-inputtext.contactInput {
    height: 58px;
    font-size: 18px;
  }

  .p-inputtext.cooperationInput {
    height: 58px;
    font-size: 18px;
  }
}