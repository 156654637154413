@import "./_colors.scss";
@import "./functions";

:root {
  color: $fill-brandSecondary;
}

.disabledFR {
  opacity: 0.5;
}

p {
  font-size: 14px;
}

label {
  font-size: 10px;

  &.disabled {
    color: #8b8b8b !important;
    font-weight: 300 !important;
  }
}

h1 {
  font-size: 26px;
  color: $fill-brandSecondary;
  margin-block-start: 0;
  margin-block-end: 0;

  span {
    color: $fill-brandPrimary;
  }
}

h2 {
  font-size: 17px;
  font-weight: 600;
}

h3 {
  font-size: 12px;
}

.wrapper {
  max-width: 100%;
}

.textBlue {
  color: $fill-brandSecondary !important;
}

.textYellow {
  color: $fill-brandPrimary !important;
}

.textGreen {
  color: $brand-primary-warranties !important;
}

.textGrey {
  color: $fill-mediumGrey !important;
}

.textSmall {
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.24px;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.textUnderlined {
  text-decoration: underline;
}

button {
  height: 40px;
  font-size: 14px;
  transition: all 0.2s ease-in;

  &:disabled {
    cursor: default !important;
  }
}

// wrapper for info icon, so overlay shows relatively to it

.imgWrapper {
  position: relative;

  img {
    margin-bottom: -6px !important;
  }
}

// custom buttons for forms
button.buttonWhite {
  border-radius: 26px;
  border: 2px solid $fill-brandPrimary;
  color: #faa200;
  width: 251px;
  font-weight: bold;
  background-color: white;
  cursor: pointer;
  outline: -webkit-focus-ring-color none;

  &:disabled {
    background-color: $fill-greyLight;
  }
}

button.buttonYellow {
  border-radius: 26px;
  border: 1px solid $fill-brandPrimary;
  color: white;
  padding: 0 24px;
  font-weight: bold;
  background-color: $fill-brandPrimary;
  cursor: pointer;
  outline: -webkit-focus-ring-color none;

  img {
    margin-right: 8px;
    margin-bottom: -1px;
  }

  &:disabled {
    background: #faa200;
    opacity: 0.5;
  }
}

button.buttonGreen {
  border-radius: 26px;
  border: 1px solid $brand-primary-warranties;
  color: white;
  padding: 0 24px;
  font-weight: bold;
  background-color: $brand-primary-warranties;
  cursor: pointer;
  outline: -webkit-focus-ring-color none;

  img {
    margin-right: 8px;
    margin-bottom: -1px;
  }

  &:disabled {
    opacity: 0.5;
  }
}

button.buttonBlue {
  border-radius: 26px;
  border: 1px solid $fill-brandSecondary;
  color: white;
  padding: 0 24px;
  font-weight: bold;
  background-color: $fill-brandSecondary;
  cursor: pointer;
  outline: -webkit-focus-ring-color none;

  img {
    margin-right: 8px;
    margin-bottom: -1px;
  }

  &:disabled {
    color: $fill-mediumGrey;
  }
}

// custom radio buttons

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 37px;
  //padding-right: 16px;
  cursor: pointer;
  line-height: 29px;
  display: inline-block;
  color: $fill-greyDark;
  font-size: 12px;
  margin-right: 5px;
}

[type='radio']:disabled + label {
  cursor: default;
}

[type='radio']:not(:checked) + label {
  font-weight: 400;
}

[type='radio']:checked + label {
  font-weight: 700;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 28px;
  height: 28px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: white;
}

[type='radio']:checked + label:before {
  border: 1px solid $fill-brandPrimary;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 18px;
  height: 18px;
  background: #faa200;
  position: absolute;
  font-weight: 700;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.dataButton {
  display: flex;
  border: 1px solid $fill-borderGrey;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  padding-left: 24px;
  justify-content: space-between;

  button {
    width: 50%;
  }

  p {
    font-weight: bold;
    margin: 11px auto;
  }

  input {
    border: none;
    height: 38px;
    width: 150px;
    color: $fill-brandSecondary;

    &:focus {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.swiper-pagination-bullet {
  background: white !important;
  border: 1px solid $fill-brandSecondary !important;
  height: 14px !important;
  opacity: 1 !important;
  width: 14px !important;
}

.swiper-pagination-bullet-active {
  background: $fill-brandSecondary !important;
  border: 1px solid $fill-brandSecondary !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: $fill-brandSecondary !important;
  top: 37% !important;
}

.scrollContainer {
  position: relative;

  &__link {
    position: absolute;
    top: -95px;
  }
}

.w100 {
  width: 100%;
}

.p20 {
  padding: 20px;
}

.scrollWrapper {
  overflow-y: scroll;
  height: 100%;

  /* width */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    -webkit-overflow-scrolling: auto;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #faa200;
    border-radius: 5px;
    border: 1px solid white;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #faa200;
  }
}

.inputLabel {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  p {
    font-size: 16px;
  }

  label {
    font-size: 16px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 29px;
  }

  h3 {
    font-size: 29px;
  }

  .wrapper {
    width: 85%;
  }

  .textSmall {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.24px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  button {
    height: 42px;
    font-size: 17px;
  }

  .dataButton {
    display: flex;
    border-radius: 26px;
    height: 42px;
    width: 100%;
    padding-left: 24px;
    justify-content: space-between;

    input {
      font-size: 17px;
      height: 40px;
    }

    button {
      margin-top: -1px;
    }
  }

  button {
    height: 42px;
    width: 251px;
    font-size: 17px;
  }

  // radio buttons
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    padding-left: 32px;
    padding-right: 0;
    line-height: 32px;
    font-size: 14px;

    @media only screen and (min-width: 1024px) {
      padding-left: 40px;
      padding-right: 10px;
    }
  }

  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
  }

  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    width: 18px;
    height: 18px;
    top: 6px;
    left: 6px;
  }
}

@media only screen and (min-width: 1200px) {
  //p,
  //a,
  //label {
  //  font-size: 13px;
  //  line-height: 21px;
  //  letter-spacing: 0.24px;
  //}

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 22px;
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  h3 {
    font-size: 29px;
  }

  .textSmall {
    font-size: 14px;
    line-height: 21px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  .dataButton {
    width: 316px;
    max-width: 100%;
  }

  .wrapper {
    width: 85%;
    max-width: 1366px;
  }
}

@media only screen and (min-width: 1600px) {
  p,
  a,
  label {
    font-size: 16px;
    line-height: 23px;
  }

  h1 {
    font-size: 46px;
  }

  h2 {
    font-size: 29px;
  }

  .dataButton {
    width: 316px;
    max-width: 100%;

    input {
      font-size: 17px;
    }
  }
}

.infobox {
  &__title {
    font-weight: 800;
    margin-bottom: pxToRem(8);
  }

  &__description {
    font-weight: 500;
    font-size: pxToRem(14);
    line-height: 16px;
  }
}

.bold {
  font-weight: 800 !important;
}
