// @import "functions";

.navigation-grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 0;
    margin-right: 0;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
}

.navigation-button {

  &--back {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    margin-bottom: 8px;

    @media only screen and (min-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 2;
      margin: 0;
    }
  }

  &--next {
    grid-column-start: 1;
    width: 100%;
    grid-column-end: 3;
    margin: 0;

    @media only screen and (min-width: 768px) {
      grid-column-start: 2;
      grid-column-end: 3;
      margin: 0;
    }
  }

  &--first-step {

  }
}